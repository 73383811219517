import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { classToClass } from 'class-transformer';

import { FacebookLiveFeed } from '../../models/FacebookLiveFeed';
import { FacebookLiveVideoProduct } from '../../models/FacebookLiveVideoProduct';
import { Product } from '../../models/Product';
import { Item } from '../../models/Item';
import { AppState } from '../../rtk/store';

export interface FacebookLiveVideoFeedState {
  feeds: FacebookLiveFeed[];
  facebookLiveVideoProducts: FacebookLiveVideoProduct[];
  product: Product | null;
  selectItem: Item | null;
}

const initialState: FacebookLiveVideoFeedState = {
  feeds: [],
  facebookLiveVideoProducts: [],
  product: null,
  selectItem: null,
};

export const FacebookLiveVideoFeedSlice = createSlice({
  name: 'facebookLiveFeeds',
  initialState: initialState,
  reducers: {
    loadFeeds: (state, action: PayloadAction<FacebookLiveFeed[]>) => {
      state.feeds = action.payload;
    },
    loadMoreFeed: (state, action: PayloadAction<FacebookLiveFeed[]>) => {
      state.feeds = state.feeds.concat(action.payload);
    },
    syncFeed: (state, action: PayloadAction<FacebookLiveFeed>) => {
      const index = state.feeds.findIndex(
        (feed) => feed.id == action.payload.id
      );
      if (index == -1) {
        state.feeds = [action.payload, ...state.feeds];
      } else {
        state.feeds[index] = action.payload;
        state.feeds = [...state.feeds];
      }
    },
    syncFeeds: (state, action: PayloadAction<FacebookLiveFeed[]>) => {
      for (let i = 0; i < action.payload.length; i++) {
        for (let j = 0; j < state.feeds.length; j++) {
          if (action.payload[i].id == state.feeds[j].id) {
            state.feeds[j] = action.payload[i];
          }
        }
      }
      state.feeds = [...state.feeds];
    },
    deSelectFeeds: (state) => {
      for (let j = 0; j < state.feeds.length; j++) {
        state.feeds[j].checked = false;
      }
      state.feeds = [...state.feeds];
    },
    processingAndUncheck: (state, action: PayloadAction<string[]>) => {
      for (let i = 0; i < state.feeds.length; i++) {
        for (let j = 0; j < action.payload.length; j++) {
          const ids = action.payload[j].split('_');
          if (ids[1] != undefined) {
            if (state.feeds[i].id == ids[1]) {
              state.feeds[i].processing = true;
              state.feeds[i].checked = false;
              break;
            }
          }
        }
      }
      state.feeds = [...state.feeds];
    },

    loadVideoProducts: (
      state,
      action: PayloadAction<FacebookLiveVideoProduct[]>
    ) => {
      state.facebookLiveVideoProducts = action.payload;
    },

    syncVideoProduct: (
      state,
      action: PayloadAction<FacebookLiveVideoProduct>
    ) => {
      const index = state.facebookLiveVideoProducts.findIndex(
        (facebookLiveVideoProduct) =>
          facebookLiveVideoProduct.id == action.payload.id
      );
      if (index == -1) {
        state.facebookLiveVideoProducts = [
          ...state.facebookLiveVideoProducts,
          action.payload,
        ];
      } else {
        state.facebookLiveVideoProducts[index] = action.payload;
        state.facebookLiveVideoProducts = [...state.facebookLiveVideoProducts];
      }
    },

    removeVideoProduct: (
      state,
      action: PayloadAction<FacebookLiveVideoProduct>
    ) => {
      state.facebookLiveVideoProducts = state.facebookLiveVideoProducts.filter(
        (facebookLiveVideoProduct) =>
          facebookLiveVideoProduct.product_id != action.payload.product_id
      );
    },

    setProduct: (state, action: PayloadAction<Product | null>) => {
      state.product = action.payload;
    },

    updateItem: (state, action: PayloadAction<Item | null>) => {
      if (state.product != null && action.payload != null) {
        if (state.product.items) {
          for (let i = 0; i < state.product.items?.length; i++) {
            if (action.payload.id == state.product.items[i].id) {
              state.product.items[i].copyWith(action.payload);
              const newProduct = classToClass(state.product);
              setProduct(newProduct);
            }
          }
        }
      }
      state.selectItem = classToClass(action.payload);
    },
  },
});

export const {
  loadFeeds,
  loadMoreFeed,
  syncFeed,
  syncFeeds,
  deSelectFeeds,
  processingAndUncheck,
  loadVideoProducts,
  syncVideoProduct,
  removeVideoProduct,
  setProduct,
  updateItem,
} = FacebookLiveVideoFeedSlice.actions;
export const selectFeeds = (state: AppState) =>
  state.facebookLiveVideoFeedSlice.feeds;
export const selectVideoProducts = (state: AppState) =>
  state.facebookLiveVideoFeedSlice.facebookLiveVideoProducts;
export const selectProduct = (state: AppState) =>
  state.facebookLiveVideoFeedSlice.product;
export const selectItem = (state: AppState) =>
  state.facebookLiveVideoFeedSlice.selectItem;
