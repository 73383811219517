import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '../../rtk/store';

import { Item } from '@/common/models/Item';

export interface ProductEditFormState {
  items: Item[];
}

const initialState: ProductEditFormState = {
  items: [],
};

export const ProductEditFormSlice = createSlice({
  name: 'ProductEditForm',
  initialState: initialState,
  reducers: {
    setItems: (state, action: PayloadAction<Item[]>) => {
      state.items = [...action.payload];
    },
  },
});

export const { setItems } = ProductEditFormSlice.actions;

export const selectItems = (state: AppState) =>
  state.productEditFormSlice.items;
