// import "antd/dist/antd.css";
import '../styles/global.scss';

import { useMemo } from 'react';
import { Provider } from 'react-redux';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { IntlProvider } from 'react-intl';
import { SWRConfig } from 'swr';

import { store } from '@/common/rtk/store';

import English from '../content/locales/en.json';
import Thai from '../content/locales/th.json';

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const messages = useMemo(() => {
    switch (router.locale) {
      case 'th':
        return Thai;
      case 'en':
        return English;
      default:
        return Thai;
    }
  }, [router.locale]);

  return (
    <>
      <Head>
        <title>Shophil</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <Provider store={store}>
        <SWRConfig
          value={{
            refreshInterval: 1000 * 60 * 5,
            revalidateOnFocus: false,
            provider: () => new Map(),
          }}>
          <IntlProvider
            locale={router.locale ?? 'th'}
            messages={messages}
            onError={() => null}>
            <Component {...pageProps} />
          </IntlProvider>
        </SWRConfig>
      </Provider>
    </>
  );
}
