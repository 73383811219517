import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { classToClass } from 'class-transformer';

import { AppState } from '../../rtk/store';

import { Order } from '@/common/models/Order';

export interface OrderFormState {
  order: Order | null;
}

const initialState: OrderFormState = {
  order: null,
};

export const OrderFormSlice = createSlice({
  name: 'OrderForm',
  initialState: initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      state.order = classToClass(action.payload);
    },
  },
});

export const { setOrder } = OrderFormSlice.actions;

export const selectDuplicates = (state: AppState) =>
  state.OrderFormSlice.order?.duplicates;

export const selectShopId = (state: AppState) =>
  state.OrderFormSlice.order?.shop_id;

export const selectOrder = (state: AppState) => state.OrderFormSlice.order;
