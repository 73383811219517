import { configureStore } from '@reduxjs/toolkit';

import { FacebookLiveVideoFeedSlice } from './services/FacebookLiveVideoFeed';
import { ShopEditFormSlice } from './services/ShopEditForm';
import { ProductEditFormSlice } from './services/ProductEditForm';
import { OrderFormSlice } from './services/OrderForm';

export const store = configureStore({
  reducer: {
    facebookLiveVideoFeedSlice: FacebookLiveVideoFeedSlice.reducer,
    shopEditFormSlice: ShopEditFormSlice.reducer,
    productEditFormSlice: ProductEditFormSlice.reducer,
    OrderFormSlice: OrderFormSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
