import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';

import { AppState } from '../../rtk/store';

export interface ShopEditFormState {
  processing: boolean;
  uploadFile: UploadFile | null;
  reRender: number[];
}

const initialState: ShopEditFormState = {
  processing: false,
  uploadFile: null,
  reRender: [],
};

export const ShopEditFormSlice = createSlice({
  name: 'shopEditForm',
  initialState: initialState,
  reducers: {
    processing: (state) => {
      state.processing = true;
    },
    fileUpdated: (state, action: PayloadAction<UploadFile | null>) => {
      console.log(action.payload);
      state.uploadFile = action.payload;
    },
    reRender: (state) => {
      state.reRender = [...state.reRender];
    },
    saveSuccess: (state) => {
      state.processing = false;
    },
    saveFail: (state) => {
      state.processing = false;
    },
    reset: (state) => {
      state.processing = false;
      state.uploadFile = null;
      state.reRender = [...state.reRender];
    },
  },
});

export const {
  processing,
  fileUpdated,
  reRender,
  saveSuccess,
  saveFail,
  reset,
} = ShopEditFormSlice.actions;

export const selectProcessing = (state: AppState) =>
  state.shopEditFormSlice.processing;
export const selectUploadFile = (state: AppState) =>
  state.shopEditFormSlice.uploadFile;
export const selectRerender = (state: AppState) =>
  state.shopEditFormSlice.reRender;
